#intro-article {
    padding-top: 20px;
    padding-bottom: 10px;
}

#portfolio-image {
    margin-top: 7rem;
    background-color: grey;
    border: .1rem solid #F5F3FA;
    width: 160px;
    height: 160px;
    
}

#space-svg-parent {
    margin-top: -2px;
    margin-left: 408px;
    width:150px;
    height:200px;
}

.intro-section-summary {
    color: white;
    text-align: center;
    padding-top: 3rem;
    /* background-image: url("../../images/space2.svg"); */
}

#intro-section-summary-paragraph {
    padding-right: 20%;
    font-size:30px;
    padding-left: 20%;
    font-weight: 200;
}


.intro-section-summary-icons {
    text-align: center;
    margin-bottom: 100px;

}

.flame {
    animation: flame-color .1s infinite;
}
#down-arrown{

    display:inline-block;
}
#full-stack{
    font-size:25px;
    transition: opacity 0.5s;
    font-weight: 200;
}

#intro-section-summary-name{
    font-size:55px;
    font-weight: 200;
}

.port-div{
    color:gold;
}


@media screen and (max-width:2900px) and (min-width:2000px){
    #portfolio-image {
        margin-top: 24rem;
        width: 190px;
        height: 190px; 
    }
    .cool{
        margin-bottom: 420px;
    }
    .cool2{
        margin-bottom: 200px;
    }
    .cool3{
        height: 240px;
    }
    #intro-section-summary-paragraph {
    
        padding-right: 10px;
        padding-left: 10px;
        font-size:55px;
        
    }
    .intro-section-summary-icons {
        text-align: center;
        margin-bottom: 200px;
        margin-top: 100px;
    
    }
   
}

@media screen and (max-width:1600px) and (min-width:1400px){
    .cool3{
        height: 100px;
    }
}
@media screen and (max-width:1030px) and (min-width:1020px){
    #space-svg-parent{
        margin-left:315px;
    }
}
@media only screen and (max-width:1200px) {}

@media only screen and (max-width:990px) {

}


@media only screen and (max-width:775px) {
    /* #intro-article {
        padding-top: 2rem;
    } */
    #portfolio-image {
        margin-top: 0rem;
        width: 15rem;
        height: 15rem;
    }
    .intro-section-summary {
        text-align: center;
        padding-top: 5rem;
    }
    #intro-section-summary-paragraph {
        padding-right: 0rem;
        font-size:20px;
        padding-Left:0px;
    }
    .intro-section-summary-icons {
        text-align: center;
        padding-left: 0rem;
    }
}

@media screen and (max-width:780px) and (min-width:760px){
    #space-svg-parent{
        margin-left:200px;
    }
}

@media only screen and (max-width:575px) {
    .intro-section-summary {
        text-align: center;
        padding-top: 0rem;
    }
    #intro-section-summary-paragraph {
        padding-top:0px;
    }
    #intro-section-summary-name {
        font-size:30px;
    }
    #intro-article {
        padding-top: 0rem;
    }
    #portfolio-image {
        margin-top: 10rem;
        margin-bottom: 3rem;
        width: 10rem;
        height: 10rem;
    }
    .intro-section-summary-icons {
        text-align: center;
        padding-left: 0
    }
    #full-stack{
        margin-top: 10px;
        font-size: 20px;
    }
    #down-arrown{
        display:none;
    }
    #space-svg-parent {
        margin-left: 20px;
    }
}

@media screen and (max-width:550px) and (min-width:500px){
    #space-svg-parent{
        margin-left:108px;
    }
}

@media screen and (max-width:420px) and (min-width:400px){
    #space-svg-parent{
        margin-left:45px;
    }
}

@media only screen and (max-width:385px) {
    #space-svg-parent{
        margin-left:25px;
    }
    #portfolio-image {
        margin-top: 90px;
    }
    
}

@media only screen and (max-width:365px) {
    #space-svg-parent{
        margin-left:20px;
    }

    #intro-section-summary-name {
        font-size:35px;
    }
    #full-stack{
        font-size: 25px;
    }
    #portfolio-image {
        margin-top: 30px;
    }
    
}


@media only screen and (max-width:340px) {
 
    #portfolio-image {
        margin-left: 5px;
        margin-top: 40px;
    }
    #intro-section-summary-name {
        font-size:25px;
    }
    #full-stack{
        font-size: 20px;
        margin-top: 40px;
    } 
    #space-svg-parent{
        margin-left:0px;
    }
    
}

@media screen and (max-width:300px) and (min-width:200px){
    #space-svg-parent{
        margin-left:-18px;
    }
}




@keyframes flame-color {
    from {
        fill: red;
    }
    to {
        height: yellow;
    }
}
