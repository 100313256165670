.distort-bottom {
    display: none;
}

.what-i-do-content {
    color: white;
    height: 20rem;
}

.interest {
    color: white;
}

.port-div-show {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
}

.grow {
    transition: all .2s ease-in-out;
}

.grow:hover {
    transform: scale(1.1);
}

.grow:hover .blob {
    transform: scale(1);
    animation: pulse-black 3s infinite;
    opacity: 1;
}

.grow:hover #click-me {
    opacity: 1;
}

#preset-revolver {
    animation-fill-mode: forwards;
}

.revolver-div {
    margin-left: 395px;
}

#none {
    display: none;
}

.stars-drop {
    animation: drop 1s infinite;
}

.form-icons {
    background-color: black;
    padding-left: 20px;
}
.contact-section{
    padding-left: 70px;
}

@media only screen and (max-width:800px) {
    .contact-section{
        padding-left:0px;
    }
    .form-icons {
      
        padding-left: 260px;
    }
    .ss {
        margin-bottom: 1rem;
    }
    .revolver-div {
        margin-left: 190px;
    }
    @keyframes bullet-glide {
        100% {
            margin-left: 300px;
        }
    }
}

@media only screen and (max-width:500px) {
    .distort-bottom {
        display: block;
        margin-bottom: 20px;
    }
    .revolver-parent {
        display: none;
    }
    .ss {
        margin-bottom: 1rem;
    }
    .fun-facts{
        margin-left: 0px;
        margin-right: 5px;
    }
    .revolver-div {
        margin-left: 15px;
    }
    .distortTop {
        margin-left: .02px;
    }
    .form-icons {
        margin-left: 80px;
        padding-left:0px;
    }
    .contact-section{
        padding-left:0px;
        padding-right:0px;
    }
    @keyframes bullet-glide {
        100% {
            margin-left: 300px;
        }
    }
}

@keyframes drop {
    .5% {
        transform: translate(0px, -500px);
    }
    100% {
        transform: translate(0px, 700px);
        opacity: .1;
    }
}