body {
    /* background: linear-gradient(45deg, #ffe4c0 50%, rgb(8, 19, 78) 50%); */
    background-color: black;
    /* background-color: rgb(8, 19, 78); */
    /* overflow-y: scroll; */
    -webkit-overflow-scrolling: touch;
    /* background-image: url("./images/intro.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; */
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.container {
    background-color: black;
    border-radius: 25px;
    padding: 20px;
    margin-bottom: 3rem;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    background:black;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px black; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: black; 
  }

@media only screen and (max-width:500px) {
    .container {
        border-radius: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        
    }
    body {
        background-color: black;
    }
}