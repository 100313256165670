.nav-item {
    margin-right: 3rem;
}

.navbar-brand {
    margin: 0rem;
}

.nav-bar{
    background-color: black;
    padding-top:0px;
}

a:hover{
    text-decoration: none;
}
#logo{
    margin-left: 50px;
}

.logo2{
    pointer-events: none;
    position: absolute;
    animation-name: logo;
    animation-duration: 5s;
    transform: translate(0px, 0px)
  }

  .logo3{
    pointer-events: none;
    position: absolute;
    animation-name: logoC;
    animation-duration: 5s;
    transform-origin: center center;
  }


#angular,#react:hover{
  background-color: black;
}
@media only screen and (max-width:500px){
    #logo{
        margin-left: 0px;
    }
}

@keyframes logo {
    .5%{
      transform: translate(40px, 40px)
    }
    100%{
      transform: translate(0px, 0px)
    }
  
  }

  @keyframes logoC {
    100%{
      transform: rotate(360deg)
    }
  
  }
