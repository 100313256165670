.blob {
    background: red;
    opacity: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 20px;
    width: 20px;
    margin-left: 20px;
}

#click-me{
    opacity: 0;
    margin-right: 25px;
}

@media only screen and (max-width:1200px) {
  .blob {
    margin-left: -15px;
  }

}
@media only screen and (max-width:1000px) {
  .blob {
    margin-left: -55px;
  }

}
@media only screen and (max-width:775px) {
    #click-me{
        margin-right: px;
    }
    .blob {
      margin-left: 180px;
    }

}
@media only screen and (max-width:575px) {
   .blob {
     margin-left: 28px;
   }

}
@media only screen and (max-width:550px) {
  .blob {
    margin-left: 90px;
  }

}
@media only screen and (max-width:450px) {
  .blob {
    margin-left: 28px;
  }

}


@media only screen and (max-width:400px) {
  .blob {
    margin-left: 9px;
  }

}
@media only screen and (max-width:365px) {
  .blob {
    margin-left: 3px;
  }

}

@media only screen and (max-width:340px) {
  .blob {
    margin-left: -15px;
  }

}

@media only screen and (max-width:300px) {
  .blob {
    margin-left: -35px;
  }

}


  @keyframes pulse-black {
    0% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    
    100% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  