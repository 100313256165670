#scroll-like {
    display: inline-block;
}
.scrollTop-like { 
    position: fixed;
    width: 23%;
    bottom: 220px;
    right: 140px;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    color: blue;
    opacity: 0.7;
}

.scrollTop-like:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}




@media only screen and (max-width: 500px){
    #scroll-like {
        display: inline-block;
    }
    .scrollTop-like { 
        position: fixed;
        width: 23%;
        bottom: 110px;
        right: 0px;
        height: 20px;
        justify-content: center;
        z-index: 1000;
        cursor: pointer;
        animation: fadeIn 0.3s;
        transition: opacity 0.4s;
        color: blue;
        opacity: 0.7;
    }
    
    .scrollTop-like:hover {
        opacity: 1;
    }
    
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
}