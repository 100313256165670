.scrollTop{
    opacity: 0;
}

#scroll {
    display: none; 
}




@media only screen and (max-width: 500px){
    #scroll {
        display: inline-block;
    }
    .scrollTop { 
        position: fixed;
        width: 10%;
        bottom: 60px;
        right: 10px;
        height: 20px;
        justify-content: center;
        z-index: 1000;
        cursor: pointer;
        animation: fadeIn 0.3s;
        transition: opacity 0.4s;
        color: grey;
        opacity: 0.7;
    }
    
    .scrollTop:hover {
        opacity: 1;
    }
    
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
}


